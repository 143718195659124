import React from 'react';
import {createRoot} from 'react-dom/client';
import {ServicePageContent} from '../components/ServicePageContent.jsx';
import ServicePageModel from '../models/service-page-model';
import JshModule from '@core/scripts/helper/jsh-module';
import * as contentful from 'contentful';

const {moduleScope} = JshModule('service/service-page-content');

const servicePage = ServicePageModel.load();


const client = contentful.createClient({
    accessToken: "not_used",
    space:  "not_used"
});

const entries = client.parseEntries(JSON.parse(servicePage.cdaEntry));
servicePage.parsedCdaEntry = entries.items[0];

createRoot(moduleScope.parentNode).render(
    <ServicePageContent model={servicePage} />
);
