import ModelContainer from "@core/scripts/components/model-container";

function load() {
    return ModelContainer.get("servicePageModel");
}

export default {
    load
};



